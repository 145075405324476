html {
	margin: 0;
	padding: 0;
	background: #fff;
}
body {
	margin: 0;
	padding: 0px;
	background: #fff;

	font-family: Roboto, Arial;
	font-size: 16px;

	* {
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
	}
	background: #F5F5F5;
}
h1 {
	text-transform: uppercase;
	margin: 0;
}
h2 {
	text-transform: uppercase;
	margin: 0;
	margin-bottom: 30px;
}
h3 {
	text-transform: uppercase;
	margin: 0;
}
h4 {
	text-transform: uppercase;
	margin: 0;
}
h5 {
	text-transform: uppercase;
	margin: 0;
}
h6 {
	text-transform: uppercase;
	margin: 0;
}

.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

.main {
	max-width: 100%;
	margin: auto;
	overflow: hidden;
	min-height: 100%;
	padding: 70px 20px;
	padding-bottom: 0;

  	.panel-box {
  		border: 1px solid #fff;
  		background: #fff;
  		padding: 10px 20px 20px 20px;
  		border-radius: 4px;
  		position:  relative;
  		float: left;

  		h2 {
  			font-size: 14px;
  			background: #fff;
  			position: absolute;
  			left: -1px;  			
  			padding: 15px 20px 15px 20px;
  			top: 0px;
  			transform: translateY(-100%);
  			border: 1px solid #fff;
  			border-top-left-radius: 4px;
  			border-top-right-radius: 4px;
			color: #8d8de7;
  		}
  	}

  	.battery-wrapper {
		width: 30%;
	}

	.temperatures-graph {
		width: 69%;
		margin-left: 1%;

		#container-temperatures {
			height: 355px;
		}
	}

	.overview-graph {
		width: 100%;
		margin-top: 80px;
	}

	.meteo-blue-iframe {
		border: none;
		margin-top: 30px;
	}
}

a.refresh {
	display: block;
	margin-top: 0px;
	padding: 20px 0;
	text-transform: uppercase;
	text-decoration: none;
	font-size: 22px;
	color: #BDBDBD;
	text-align: center;

	&:hover {
		background: #00C853;
		color: #fff;
	}
}
.copyright {
	text-align: center;
	padding: 20px 0 40px 0;
	font-size: 12px; 
	vertical-align: middle;

	img { 
		margin-right: 10px;
		position: relative;
		top: 7px;
	}
}


.highcharts-credits {
	border: 1px solid red;
}