@font-face {
    font-family: 'Salt';
    src:    url('../fonts/salt.eot');
    src:    url('../fonts/salt.eot') format('embedded-opentype'),
        url('../fonts/salt.ttf') format('truetype'),
        url('../fonts/salt.woff') format('woff'),
        url('../fonts/salt.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class^="salt-icon"], [class*=" salt-icon"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'Salt' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.salt-icon-move:before {
    content: "\e900";
}
.salt-icon-move-f:before {
    content: "\e901";
}
.salt-icon-touch:before {
    content: "\e902";
}
.salt-icon-touch-f:before {
    content: "\e903";
}
.salt-icon-loop-1:before {
    content: "\e904";
}
.salt-icon-trash:before {
    content: "\e905";
}
.salt-icon-edit:before {
    content: "\e906";
}
.salt-icon-sliders-circle:before {
    content: "\e907";
}
.salt-icon-sliders-circle-f:before {
    content: "\e908";
}
.salt-icon-fish:before {
    content: "\e909";
}
.salt-icon-world-2:before {
    content: "\e90a";
}
.salt-icon-wristwatch:before {
    content: "\e90b";
}
.salt-icon-light:before {
    content: "\e90d";
}
.salt-icon-look:before {
    content: "\e90e";
}
.salt-icon-love:before {
    content: "\e90f";
}
.salt-icon-moon:before {
    content: "\e910";
}
.salt-icon-nature:before {
    content: "\e911";
}
.salt-icon-play:before {
    content: "\e912";
}
.salt-icon-rain:before {
    content: "\e913";
}
.salt-icon-settings:before {
    content: "\e914";
}
.salt-icon-signal:before {
    content: "\e915";
}
.salt-icon-signal-1:before {
    content: "\e916";
}
.salt-icon-signal-3:before {
    content: "\e917";
}
.salt-icon-sun:before {
    content: "\e918";
}
.salt-icon-watch:before {
    content: "\e919";
}
.salt-icon-plus-circle:before {
    content: "\e91a";
}
.salt-icon-plus-circle-f:before {
    content: "\e91b";
}
.salt-icon-info-circle-f:before {
    content: "\e91c";
}
.salt-icon-infinite:before {
    content: "\e91d";
}
.salt-icon-infinite-loop:before {
    content: "\e91e";
}
.salt-icon-angle-down:before {
    content: "\e91f";
}