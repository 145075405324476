$mediaqueries: false;
$dark: #000;
$white: #fff;
$gutter: 30px;

// Global HTML font size resets:
$screen-xs-font			: px(12);
$screen-sm-font			: px(12);
$screen-md-font			: px(14);
$screen-lg-font			: px(16);

// Breakpoints:
$xs						: 480;
$sm						: 768;
$md						: 992;
$lg						: 1200;

$screen-xs				: px($xs);
$screen-sm				: px($sm);
$screen-md				: px($md);
$screen-lg				: px($lg);


@if $mediaqueries == true {
	body:before {
		position: fixed;
		z-index: 999;
		display: inline-block;
		height: px(40);
		line-height: px(40);
		background: rgba($dark,.8);
		color: $white;
		text-align: center;
		padding: 0 $gutter;
	}
} @else {
	body:before {
		display: none;
		content: '';
	}
}
@media (min-width: 0)			{ body:before { content: 'Breakpoint: < XS ( < #{$xs} )'; } }
@media (min-width: $screen-xs)	{ body:before { content: 'Breakpoint: >= XS ( min-width: #{$xs} )'; } }
@media (min-width: $screen-sm)	{ body:before { content: 'Breakpoint: >= SM ( min-width: #{$sm} )'; } }
@media (min-width: $screen-md)	{ body:before { content: 'Breakpoint: >= MD ( min-width: #{$md} )'; } }
@media (min-width: $screen-lg)	{ body:before { content: 'Breakpoint: >= LG ( min-width: #{$lg} )'; } }



@media (max-width: $screen-xs) {
	body {
		.main {
			padding: 0;

			h2 {
				display: none;
			}

			.temperatures-graph {
				margin-left: 0;
			}

			.panel-box {
				float: none;
				width: 100%;
				margin-top: 30px;

				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}