.button {
	display: inline-block;
	height: 54px;
	line-height: 20px;
	padding: 17px 22px;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 5px;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	vertical-align: middle;
	i {
		margin-right: 18px;
	}

	&.small {
		padding: 6px 25px;
		height: 32px;
		font-size: 12px;
		i {
			margin-left: -10px;
			margin-right: 8px;
		}
	}

	&.very-small {
		border: 1px solid red;
		padding: 5px 20px;
		height: 30px;
		font-size: 11px;
		i {
			margin-left: -10px;
			margin-right: 8px;
			font-size: 10px;
		}
	}
	&.green {
		background: #52c24f;
		color: #fff;

		&:hover {
			background-color: #43b440;
		}
	}
	&.orange {
		background: #ff9800;
		color: #fff;

		&:hover {
			background-color: #e78a00;
		}
	}
	&.blue {
		background: #6d6ddb;
		color: #fff;

		&:hover {
			background-color: #5555dc;
		}
	}
	&.red {
		background: #c0392b;
		color: #fff;

		&:hover {
			background-color: #a61708;
		}
	}

	&.button {
		border: none;
	}
}