// Settings
$size: 100%;
$speed: 2.25s;

// Container Settings
$batteryHeight: 260px;
$batteryRadius: 1px;
$batteryColor: #263238;
$batteryBackground: white;
$batteryHighlight: rgba($batteryBackground, 0.325);


$zeroColor		: #d50000;
$middleColor	: #FFC107;
$fullColor		: #64DD17;

.battery-wrapper {
	width: $size;

  	.battery {
  		margin-top: 20px;
		display: inline-block;
		position: relative;
		width: $size;
		height: $batteryHeight / 2;
		box-shadow: 0 0 0 2px $batteryColor;
		background: $batteryBackground;
		border-radius: $batteryRadius;

		.socket_pos {
			position: absolute;
			top: -12px;
			left: 15px;
			width: 30px;
			height: 10px;
			background: rgba(213,0,0, 0.7);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
		.socket_neg {
			position: absolute;
			top: -12px;
			right: 15px;
			width: 30px;
			height: 10px;
			background: rgba(33,33,33, 0.7);
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
		}
		
		&:after {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-right: ($batteryHeight * 1) solid transparent;
			border-bottom: ($batteryHeight / 2 ) solid $batteryHighlight;
		}


		.liquid {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			width: $size;
			background: $zeroColor;

			&.low_battery_status {
				background: $zeroColor;
			}
			&.ok_battery_status {
				background: $middleColor;
			}
			&.nice_battery_status {
				background: $fullColor;
			}
		}

		.status {
			display: none;
		}
	}

	.battery-status {
		margin-top: 20px;
		background: #EEEEEE;
		border: 1px solid #E0E0E0;

		.battery-stat {
			font-size: 12px;
			padding: 6px 10px;
			font-weight: 700;
			span {
				font-weight: 400;
			}

			&:nth-child(2n) {
				background: #F5F5F5;
			}

			&:last-child {
				border: none;
			}
		}
	}
}