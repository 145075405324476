$rem-output: false; // set to "true" for output calculated in REMs

@function px($px, $base: 16) {
	$value: $px+px;
	@if $rem-output == false {
		$value: $px+px;
	} @else {
		@if $px == 1 {
			$value: 1px;
		} @else {
			$value: $px / $base;
			$value: $value+rem;
		}
	}
	@return $value;
}